import React, {useState, useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import {showMessage} from "store/messageSlice";
import {setMandatoryFields, setSaModal, setSelectedResolution} from "store/resolutionsSlice";
import {hasBeneficiaryDataChanged} from "../IsBeneficiaryDataChanged";
import {hasRepresentativeChanged} from "../HasRepresentaticeChanged";
import createBeneficiary from "app/Api Calls/CreateBeneficiary";
import updateBeneficiary from "app/Api Calls/UpdateBeneficiary";
import updateRepresentative from "app/Api Calls/UpdateRepresentative";
import createRepresentative from "app/Api Calls/CreateRepresentative";
import {resetUserForm, setBeneficiaryHasOpenTickets, setBeneficiaryId} from "store/userInfoSlice";
import {resetKnowledgeBase, setConfirmation, setConfirmationThunk} from "store/knowledgeBasePageSlice";
import {clearBeneficiaryLastSearchParams, clearFetchedBeneficiaries} from "store/beneficiaryFetchSlice";
import {setCallerId, setIsCarrierOPEKA, setIsSubmitingTicket, setSelectedGeography} from "store/ticketCreateSlice";
import {setIsCommentSubmitted, setOriginalAsignee, setTeam} from "store/editTicketSlice";
import updateTicket from "app/Api Calls/UpdateTicket";
import submitTicket from "app/Api Calls/SubmitCreateTicket";
import assignTeamToTicket from "app/Api Calls/AssignTeamToTicket";
import CategoriesDropdown from "../CategoriesDropdown";
import BeneficiaryDataChangedModal from "app/main/tickets/create-ticket/BeneficiaryDataChangedModal";
import MultiStepSelectModal from "app/main/tickets/CRUD Ticket/EditTicket/SaModalAssignment";
import performActionOnTicket from "../../../Api Calls/PerformActionOnTicket";
import {
    clearFetchedRepresentatives,
    clearRepresentativeLastSearchParams,
    setAllRepresentativeLastSearchParams
} from "store/representaticeFetchSlice";
import {setFollowupTicketId} from "store/historyTicketSlice";


function SubmitWithResolution({editMode, ticketId, onUpdate}) {
    const selectedResolution = useSelector(
        (state) => state.resolutionsSlice.selectedResolution
    );
    const resolutionOptions = useSelector(
        (state) => state.resolutionsSlice.filteredResolutionOptions
    );
    const callerId = useSelector((state) => state.ticketSlice.callerId);
    const activeStatus = useSelector((state) => state.ticketSlice.activeStatus);
    const [showModal, setShowModal] = useState(false);
    const [showBeneficiaryModal, setShowBeneficiaryModal] = useState(false);
    const [showRepresentativeModal, setShowRepresentativeModal] = useState(false);
    const [showSaModal, setShowSaModal] = useState(false);
    const saModal = useSelector((state) => state.resolutionsSlice.saModal);
    const [isLoading, setIsLoading] = useState(false);
    const erotisi = useSelector((state) => state.userInfoSlice.erotisi);
    const {selectedSubSubthematic, selectedSubthematic, subSubthematics} =
        useSelector((state) => state.knowledgeBasePageSlice);
    const activeFlags = useSelector(
        (state) => state.knowledgeBasePageSlice.activeFlags
    );
    //Beneficiary fields
    const userFilled = useSelector((state) => state.userInfoSlice.userFilled);

    const hasBeneficiaryFetched = useSelector(
        (state) => state.userInfoSlice.hasBeneficiaryFetched
    );
    const beneficiaryId = useSelector(
        (state) => state.userInfoSlice.beneficiaryId
    );
    const originalAsignee = useSelector((state) => state.editTicketSlice.originalAsignee);
    const differencesFromOriginalBeneficiary = useSelector((state) => state.beneficiaryFetchSlice.differencesFromOriginalBeneficiary)
    const originalBeneficiaryData = useSelector(
        (state) => state.userInfoSlice.originalBeneficiaryData
    );
    let isBeneficiaryDataChanged = false;
    let isRepresentativeDataChanged = false;
    const thematicId = useRef(null);
    const currentBeneficiary = useSelector((state) => state.userInfoSlice.currentBeneficiary)
    //Representative fields
    const representativeFilled = useSelector(
        (state) => state.userInfoSlice.representativeFilled
    );
    const representativeId = useSelector(
        (state) => state.userInfoSlice.representativeId
    );
    const originalRepresentativeData = useSelector(
        (state) => state.userInfoSlice.originalRepresentativeData
    );
    const currentRepresentative = useSelector((state) => state.userInfoSlice.currentRepresentative)
    const team = useSelector((state) => state.editTicketSlice.team);
    //Knowledge Base fields

    const selectedGeography = useSelector((state) => state.ticketSlice.selectedGeography);
    // To be able to always have an answer to the edit ticket
    const isCommentSubmitted = useSelector(
        (state) => state.editTicketSlice.isCommentSubmitted
    );

    //mandatory Fields for each resolution
    const mandatoryFields = useSelector(
        (state) => state.resolutionsSlice.mandatoryFields
    );
    const [resolutionClicked, setResolutionClicked] = useState(false);
    const shouldCreateBeneficiary = useSelector(
        (state) => state.userInfoSlice.shouldCreateBeneficiary
    );

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const shouldCreateRepresentative = useSelector((state) => state.representativeFetchSlice.shouldCreateRepresentative)
    const hasRepresentativeFetched = useSelector((state) => state.representativeFetchSlice.hasRepresentativeFetched);
    const followupTicketId = useSelector((state)=> state.historyTicketSlice.followupTicketId)
    useEffect(() => {
        if(selectedResolution){
        if (mandatoryFields.length > 0 && resolutionClicked ) {
            if (mandatoryFields.every((field) => field.filled)) {
                setShowModal(true);
            } else {
                dispatch(
                    showMessage({
                        message: `Δεν έχετε συμπληρώσει τα υποχρεωτικά πεδία`,
                        autoHideDuration: 2000,
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                        },
                        variant: "error",
                    })
                );

                const timer = setTimeout(() => {
                    dispatch(setSelectedResolution(null));
                }, 1500);

                return () => {
                    clearTimeout(timer);
                };
            }
        }
        }
    }, [mandatoryFields, dispatch]);


    function isObjectEmpty(obj) {
        return obj && typeof obj === 'object' && Object.keys(obj).length === 0;
    }

    const getThematicId = (state) => {
        const selectedSubSubthematic = state.knowledgeBasePageSlice.selectedSubSubthematic?.value;
        const selectedSubthematic = state.knowledgeBasePageSlice.selectedSubthematic?.value;
        const selectedThematic = state.knowledgeBasePageSlice.selectedThematic?.value;

        return selectedSubSubthematic || selectedSubthematic || selectedThematic || null;
    };

    //checking if beneficiary data is changed
    if (hasBeneficiaryFetched || !shouldCreateBeneficiary) {
        isBeneficiaryDataChanged = hasBeneficiaryDataChanged(originalBeneficiaryData, currentBeneficiary);

    }
    // Checking if representative changed
    if (hasRepresentativeFetched || !shouldCreateRepresentative) {
        isRepresentativeDataChanged = hasRepresentativeChanged(originalRepresentativeData, currentRepresentative);
    }

    //Formatting Resolutions for the Select
    const formattedResolutionOptions = resolutionOptions
        .map((option) => ({
            value: option.label,
            label: option.label,
            labelEn: option.labelEn,
            toTicketStatusId: option.toTicketStatusId,
            id: option.id,
        }))
        .sort((a, b) => {
            if (a.toTicketStatusId === 5 && b.toTicketStatusId !== 5) {
                return -1;
            }
            if (b.toTicketStatusId === 5 && a.toTicketStatusId !== 5) {
                return 1;
            }
            return 0;
        });

    //Handling Selected Resolution
    const handleSelect = (selectedOption) => {
        setResolutionClicked(true);
        dispatch(setSelectedResolution(selectedOption));
    };

    //Handling The No option in the Beneficiary Modal
    const handleBeneficiaryCloseModal = () => {
        dispatch(setSelectedResolution(null));
        setShowBeneficiaryModal(false);
    };

    //Handling  the Yes option in the Beneficiary Modal
    const handleConfirmation = async (confirmed) => {
        setIsLoading(true);
        setShowModal(false);

        if (confirmed) {
            const resultAction = await dispatch(setConfirmationThunk(selectedResolution));

            if (setConfirmationThunk.fulfilled.match(resultAction)) {
                thematicId.current = resultAction.payload.thematicId;
            }
            if (isBeneficiaryDataChanged) {
                setIsLoading(false);
                setShowBeneficiaryModal(true);
            } else {
                if (selectedResolution.labelEn === "Move to SA") {
                    if ((!isCommentSubmitted && selectedResolution.value !== "Reopen") && editMode) {
                        dispatch(setSelectedResolution(null));
                        dispatch(setSaModal(false));
                        setIsLoading(false);
                        return showErrorMessage(new Error("COMMENT_NOT_SUBMITTED"));
                    }
                    setShowBeneficiaryModal(false);
                    // setShowRepresentativeModal(false);
                    dispatch(setSaModal(true));
                    setIsLoading(false);
                    setShowSaModal(true);
                } else {

                    handleSubmit();
                }
            }
        } else {
            setIsLoading(false);
            dispatch(setSelectedResolution(null));
        }
    };

    const handleBeneficiary = async () => {
        let newBeneficiaryId = null;
        let newRepresentativeId = null;
        let beneficiaryErrorOccurred = false;
        let representativeErrorOccurred = false;
        let errorMessage = "";
        const areAllValuesNull = (obj) => {
            return Object.values(obj).every(value => value === null);
        };

        try {
            // Beneficiary handling
            if (userFilled && !hasBeneficiaryFetched && shouldCreateBeneficiary) {
                try {
                    newBeneficiaryId = await createBeneficiary(currentBeneficiary);
                } catch (error) {
                    console.error('An error occurred during beneficiary creation:', error);
                    beneficiaryErrorOccurred = true;
                    errorMessage = "BENEFICIARY_CREATION_FAILED";
                }
            } else if (isBeneficiaryDataChanged) {
                try {
                    const response = await updateBeneficiary(currentBeneficiary);
                    newBeneficiaryId = response?.data?.id;
                } catch (error) {
                    console.error('An error occurred during beneficiary update:', error);
                    beneficiaryErrorOccurred = true;
                    errorMessage = "BENEFICIARY_UPDATE_FAILED";
                }
            } else if (!editMode && !shouldCreateBeneficiary) {
                newBeneficiaryId = originalBeneficiaryData.id;
            }

            // Representative handling
            if (representativeFilled && areAllValuesNull(originalRepresentativeData) && !areAllValuesNull(currentRepresentative)) {
                try {
                    newRepresentativeId = await createRepresentative(currentRepresentative);
                } catch (error) {
                    console.error('An error occurred during representative creation:', error);
                    representativeErrorOccurred = true;
                    errorMessage = "REPRESENTATIVE_CREATION_FAILED";
                }
            } else if (isRepresentativeDataChanged && !areAllValuesNull(currentRepresentative)) {
                try {
                    await updateRepresentative(currentRepresentative?.id,currentRepresentative);
                } catch (error) {
                    console.error('An error occurred during representative update:', error);
                    representativeErrorOccurred = true;
                    errorMessage = "REPRESENTATIVE_UPDATE_FAILED";
                }
            } else if (!editMode && !shouldCreateRepresentative && originalRepresentativeData && originalRepresentativeData.id) {
                newRepresentativeId = originalRepresentativeData.id;
            }

        } catch (error) {
            console.error("An overarching error occurred:", error);
            // Assuming error handling like showing a modal or setting a loading state might be here.
            // Removed duplicate catch block for correction.
            return {errorOccurred: true, errorMessage: "GENERAL_FAILURE"};
        }

        return {
            newBeneficiaryId,
            newRepresentativeId,
            beneficiaryErrorOccurred,
            representativeErrorOccurred,
            errorMessage
        };
    };


    const clearData = () => {
        dispatch(resetUserForm());
        dispatch(resetKnowledgeBase());
        dispatch(setSelectedResolution(null));
        dispatch(clearFetchedBeneficiaries());
        dispatch(clearBeneficiaryLastSearchParams());
        dispatch(setBeneficiaryHasOpenTickets(false));
        dispatch(clearFetchedRepresentatives());
        dispatch(clearRepresentativeLastSearchParams());
        dispatch(setBeneficiaryId(null));
        dispatch(setTeam(null));
        dispatch(setIsCarrierOPEKA(false));
        dispatch(setSelectedGeography(null));
        dispatch(setCallerId(null));
        dispatch(setOriginalAsignee(null));
        dispatch(setMandatoryFields([]));
        thematicId.current = null;
        dispatch(setSelectedGeography(null));
        dispatch(setFollowupTicketId(null));
    }

    //Handling the submission of the ticket
    const handleSubmit = async (skipFinalActions = false) => {
        // Start loading indicator
        setIsLoading(true);
        let uuid = null;
        let modifiedCallerId = callerId;
        // Convert modifiedCallerId to -1 if it is set to "#"
        if (modifiedCallerId === "#") {
            modifiedCallerId = -1;
        }

        // Block for Editing an Existing Ticket
        if (editMode) {
            // Ensure comments are submitted if required
            if (!isCommentSubmitted && selectedResolution.value !== "Reopen") {
                dispatch(setSelectedResolution(null));
                setShowModal(false);
                setIsLoading(false);
                showErrorMessage(new Error("COMMENT_NOT_SUBMITTED"));
                return;
            }
            //Handling beneficiary for the edit mode
            // Update the ticket
            try {
                const resolutionPayload = {
                    actionCode: selectedResolution.id,
                    ticketId: ticketId,
                };
                await performActionOnTicket(
                    resolutionPayload
                )
                dispatch(setIsCommentSubmitted(false));

            } catch (error) {
                console.error("An error occurred while updating the ticket:", error);
                setShowModal(false);
                setIsLoading(false);
                showErrorMessage(error);
                return;
            }
        }
        // Block for Creating a New Ticket
        if (!editMode) {
            dispatch(setIsSubmitingTicket(true));
            // Handling beneficiary for create
            const {
                newBeneficiaryId,
                newRepresentativeId,
                beneficiaryErrorOccurred,
                representativeErrorOccured,
                errorMessage
            } = await handleBeneficiary();
            if (beneficiaryErrorOccurred) {
                setShowModal(false);
                setIsLoading(false);
                showErrorMessage(new Error(errorMessage));
                dispatch(setIsSubmitingTicket(false));
                return;
            }

            if (representativeErrorOccured) {
                setShowModal(false);
                setIsLoading(false);
                showErrorMessage(new Error(errorMessage));
            }
            try {
                const response = await submitTicket(
                    selectedResolution,
                    activeFlags,
                    newRepresentativeId !== null ? newRepresentativeId : representativeId,
                    erotisi,
                    thematicId.current,
                    newBeneficiaryId !== null ? newBeneficiaryId : beneficiaryId,
                    activeStatus,
                    modifiedCallerId,
                    skipFinalActions && !editMode,
                    selectedGeography && selectedGeography.value ? selectedGeography.value : null,
                    followupTicketId
                );

                if (!skipFinalActions) {
                    navigate(`/tickets`);
                    clearData();
                    showSuccessMessage();
                }
                return response;

            } catch (error) {
                setShowModal(false);
                setIsLoading(false);
                if (error.type === "RESOLUTION_ERROR") {
                    uuid = error.uuid || null;
                    showErrorMessage(error.error || error.message, uuid);
                    clearData();
                    navigate(`/ticket/${error.ticketId}`);
                } else {
                    // Handle other errors or show a generic error message
                    showErrorMessage(error.error || error);
                }
                return null;
            } finally {
                dispatch(setIsSubmitingTicket(false));

            }
        }

        if (!skipFinalActions) {
            // Clear data, show success message, and navigate to tickets page if no sa
            clearData();
            showSuccessMessage();
            navigate("/tickets");
        }
    };
    const showSuccessMessage = () => {
        dispatch(
            showMessage({
                message: "Το ticket καταχωρήθηκε επιτυχώς",
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
                variant: "success",
            })
        );
    };

    const showErrorMessage = (error, uuid = null) => {
        let message;
        let autoHideDuration = 2000;
        switch (error.message) {
            case "COMMENT_NOT_SUBMITTED":
                message =
                    "Προσθέστε Απάντηση στο Ticket για να μπορέσετε να εκτελέσετε ενέργεια σε αυτό";
                break;
            case "REPRESENTATIVE_CREATION_OR_UPDATE_FAILED":
                message = "Πρόβλημα κατα την καταχώρηση ή ενημέρωση του αντιπροσώπου";
                break;
            case "USER_NOT_FOUND":
                message = "Δεν βρέθηκε χρήστης για ανάθεση του ticket";
                break;
            case "BENEFICIARY_CREATION_FAILED":
                message = "Πρόβλημα κατα την καταχώρηση του δικαιούχου";
                break;
            case "TICKET_CREATION_OR_UPDATE_FAILED":
                message = "Πρόβλημα κατα την καταχώρηση ή ενημέρωση του ticket";
                break;
            case "Δεν βρέθηκε χρήστης για ανάθεση του ticket":
                message = `Δεν βρέθηκε χρήστης για ανάθεση του ticket ${uuid}`;
                autoHideDuration = 15000;
                break;
            case "Ο χρήστης δεν έχει δικαιώματα ενεργειών":
                message = "Ο χρήστης δεν έχει δικαιώματα ενεργειών";
                break;
            case "Problem with sa assign":
                message = "Πρόβλημα κατά την ανάθεση SA στο ticket"
                break;
            case "BENEFICIARY_UPDATE_FAILED":
                message = "Πρόβλημα κατα την ενημέρωση του δικαιούχου";
                break;
            case "REPRESENTATIVE_CREATION_FAILED":
                message = "Πρόβλημα κατα την καταχώρηση του αντιπροσώπου";
                break;
            case "REPRESENTATIVE_UPDATE_FAILED":
                message = "Πρόβλημα κατα την ενημέρωση του αντιπροσώπου";
                break;
            case "RESOLUTION_ERROR":
                message =
                    `Υπήρξε πρόβλημα κατά την αποθήκευση της ενέργειας του ticket ${uuid}`;
                break;
            case "TICKET_CREATION_ERROR":
                message = "Υπήρξε πρόβλημα κατά την δημιουργία του ticket";
                break;
            default:
                message = "Υπήρξε πρόβλημα κατά την καταχώρηση του ticket";
        }
        dispatch(
            showMessage({
                message,
                autoHideDuration,
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
                variant: "error",
            })
        );
    };

    // Case that the beneficiary data are updated and we handle the submission of the ticket
    const handleSubmitWithBeneficiaryUpdate = async () => {
        setShowBeneficiaryModal(false);

        if (selectedResolution.labelEn === "Move to SA") { // Check if saModal from Redux is tru
            setShowSaModal(true);
            dispatch(setSaModal(true));
        } else {
            // If not, proceed with the submission
            handleSubmit();
        }
    };


    const handleSubmitWithSaModal = async (selectedTeam) => {
        try {
            let tempTicketId = ticketId;
            const skipFinalActions = !editMode;

            if (!editMode) {
                const handleSubmitResult = await handleSubmit(skipFinalActions);
                if (handleSubmitResult && handleSubmitResult.success) {
                    tempTicketId = handleSubmitResult.ticketId; // Ensure this aligns with your response structure
                } else {
                    // Handle case where handleSubmit did not succeed
                    console.error("handleSubmit failed");
                    return;
                }
            }

            // Proceed with assigning the team to the ticket
            const response = await assignTeamToTicket(tempTicketId, selectedTeam);
            if (response == 200) {
                dispatch(
                    showMessage({
                        message: "Η ανάθεση SA του ticket έγινε επιτυχώς",
                        autoHideDuration: 2000,
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                        },
                        variant: "success",
                    })
                );
                dispatch(setSaModal(false));

                // Actions after successful team assignment and only if not in edit mode
                if (!editMode) {
                    // Navigate to the newly created or updated ticket
                    navigate(`/ticket/${tempTicketId}`);
                    clearData();
                    showSuccessMessage();
                }

                // Callback function to refetch or update the ticket view
                if (typeof onUpdate === 'function' && editMode) {
                    onUpdate(true);
                }
            }
        } catch (error) {
            console.error("Error in handleSubmitWithSaModal:", error);
            showErrorMessage(error);
        }
    };


    return (
        <>
            <CategoriesDropdown
                options={formattedResolutionOptions}
                value={selectedResolution}
                onChange={handleSelect}
                placeholder="Eπιλογή ενέργειας"
                isLoading={false}
                backgroundColor="#eeeeee"
                noOptionsMessage="Δεν υπάρχουν διαθέσιμες ενέργειες"
                cursor="pointer"
                isDisabled={
                    editMode &&
                    !(
                        (selectedSubthematic && subSubthematics.length === 0) ||
                        selectedSubSubthematic
                    )
                }
            />
            {
                //Checking if the beneficiary data are changed and we show the modal to see the beneficiary data changes
                isBeneficiaryDataChanged && (
                    <BeneficiaryDataChangedModal
                        open={showBeneficiaryModal}
                        handleClose={handleBeneficiaryCloseModal}
                        originalBeneficiaryData={originalBeneficiaryData}
                        currentBeneficiaryData={currentBeneficiary}
                        showError={showErrorMessage}
                        handleSubmitWithBeneficiaryUpdate={
                            handleSubmitWithBeneficiaryUpdate
                        }
                    />
                )
            }

            {/* Show Sa modal to assign with ΧΩΡΙΚΌΤΗΤΑ */}
            {showSaModal && <MultiStepSelectModal onFinish={handleSubmitWithSaModal}/>}


            <Dialog open={showModal} onClose={() => handleConfirmation(false)}>
                <DialogTitle className="text-xl font-bold">
                    Επιβεβαίωση ενέργειας
                </DialogTitle>
                <DialogContent className="text-lg">
                    <p>
                        Είστε σίγουρος/η ότι θέλετε να εκτελέσετε την επιλεγμένη ενέργεια{" "}
                        <strong>{selectedResolution?.label}</strong>;
                    </p>
                </DialogContent>
                <DialogActions className="mt-4">
                    <Button
                        className="text-lg mr-2 text-white bg-red-500 hover:bg-red-600"
                        style={{backgroundColor: "red", fontSize: "16px", color: "white"}}
                        onClick={() => handleConfirmation(false)}
                        disabled={isLoading}
                    >
                        Όχι
                    </Button>
                    <Button
                        className="text-lg text-white bg-blue-500 hover:bg-blue-600"
                        style={{
                            backgroundColor: "green",
                            fontSize: "16px",
                            color: "white",
                        }}
                        onClick={() => handleConfirmation(true)}
                        disabled={isLoading}
                    >
                        Ναι
                    </Button>
                </DialogActions>

                {isLoading && (
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "rgba(255, 255, 255, 0.8)",
                        }}
                    >
                        <CircularProgress/>
                    </div>
                )}
            </Dialog>
        </>
    );
}

export default SubmitWithResolution;


// const handleRepresentativeCloseModal = () => {
//   dispatch(setSelectedResolution(null));
//   setShowRepresentativeModal(false);
// };


// const handleSubmitWithRepresentativeUpdate = async () => {
//   if (selectedResolution.labelEn === "Move to SA") {
//     setShowSaModal(true);
//     dispatch(setSaModal(true));
//   } else {
//     // If not, proceed with the submission
//     handleSubmit();
//   }
// };

{/* /* {
        //Checking if the beneficiary data are changed and we show the modal to see the beneficiary data changes
        isRepresentativeDataChanged && (
          <RepresentativeDataChangedModal
            open={showRepresentativeModal}
            handleClose={handleRepresentativeCloseModal}
            originalRepresentativeData={originalRepresentativeData}
            currentData={currentRepresentative}
            handleSubmitWithRepresentativeUpdate={
              handleSubmitWithRepresentativeUpdate
            }
          />
        )
      } */
}


// If the Representative's data has changed, show the Representative's modal
// if (isRepresentativeDataChanged) {
//   setShowRepresentativeModal(true);
// }